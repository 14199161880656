import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ResetIcon from '@material-ui/icons/Refresh';

const ResetFiltersButton = ({ hasFilters, resetFilters }) => {
  return hasFilters
    ? (
      <Button
        fullWidth  
        onClick={resetFilters}
        startIcon={<ResetIcon />}
        style={{ position: 'relative', zIndex: 2 }}
        variant="contained">Reset All Filters</Button>
    )
    : (<span />);
};

ResetFiltersButton.propTypes = {
  hasFilters: PropTypes.bool.isRequired,
  resetFilters: PropTypes.func.isRequired,
};

export default ResetFiltersButton;
