import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTracker } from '@colliers-international/usage-tracker';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import DisableIcon from '@material-ui/icons/PowerSettingsNew';
import ReplayIcon from '@material-ui/icons/Replay';
import ShowFiltersIcon from '../../assets/filter-icon.svg';
import Can from '../../auth/ability';
import datatypes from '../../common/datatypes';
import ParcelMenu from './ParcelMenu';
import styles from './module.css';

const buttonOffColor = grey[800];
const MapMenu = ({ classes, router, location, parcelsEnabled, ...props }) => {
  const [datatypeIconIsHovered, setdatatypeIconIsHovered] = useState(true);
  const [filterIconIsHovered, setFilterIconIsHovered] = useState(false);
  const [turnOffIconIsHovered, setTurnOffIconIsHovered] = useState(false);
  const { trackEvent } = useTracker();
  const showFilters = (key) => () => props.showFilters(key);
  const disableDatatype = (key) => () => {
    setTurnOffIconIsHovered(false);
    props.disableDatatype(key);
  };
  const enableDatatype = (key) => () => {
    const datatypeName = key.charAt(0).toUpperCase() + key.slice(1);
    trackEvent({ category: 'Discover Menu', action: 'Clicked', name: `${datatypeName} Icon` });
    router.push({ ...location, query: { ...location.query, currentListView: key } });
    props.enableDatatype(key);
  };
  const disableAllDatatypes = () => () => props.disableAllDatatypes();
  const disableAllFilters = () => () => props.disableAllFilters();
  const disableResetMode = () => () => props.disableResetMode();
  const enableResetMode = () => () => props.enableResetMode();
  const enableResetAllModal = () => () => props.enableResetAllModal();
  const enableResetFilterModal = () => () => props.enableResetFilterModal();
  const { resetOpen, resetFilterMenu, resetAllMenu } = props.searches;

  const datatypeIconMouseEnter = () => { setdatatypeIconIsHovered(true); };
  const datatypeIconMouseLeave = () => { setdatatypeIconIsHovered(false); };
  const filterIconMouseEnter = () => { setFilterIconIsHovered(true); };
  const filterIconMouseLeave = () => { setFilterIconIsHovered(false); };
  const turnOffIconMouseEnter = () => { setTurnOffIconIsHovered(true); };
  const turnOffIconMouseLeave = () => { setTurnOffIconIsHovered(false); };

  return (
    <div className={classes.menuContainer}>
      <Can do="read" on="parcels">
        <ParcelMenu
          disable={props.disableMapParcels}
          enable={props.enableMapParcels}
          filtersActive={props.searches?.parcel?.filterCount}
          isEnabled={parcelsEnabled}
          location={location}
          offColor={buttonOffColor}
          onFilterClick={showFilters} />
      </Can>
      {props.visibleDatatypes.map((key) => {
        const { getNonDefaultFilters, Icon, color, plural } = datatypes[key];
        const { filters, isEnabled } = props.searches[key];
        const backgroundColor = isEnabled ? color : buttonOffColor;

        const nonDefaultFilters = getNonDefaultFilters(filters);

        const filtersActiveCount = () => {
          let count = 0;
          for (const datatypePropKey in nonDefaultFilters) {
            if (datatypePropKey === '_id') continue;
            if (datatypePropKey === '__tenant') continue;
            if (Array.isArray(nonDefaultFilters[datatypePropKey])) count += nonDefaultFilters[datatypePropKey].length;
            if (typeof nonDefaultFilters[datatypePropKey] === 'string') count += 1;
            if (!Array.isArray(nonDefaultFilters[datatypePropKey]) 
              && typeof nonDefaultFilters[datatypePropKey] === 'object' 
              && Object.keys(nonDefaultFilters[datatypePropKey]).length > 0) count += 1;
          } 
          return count;
        };

        const filtersActive = (filters) => {
          const filterCount = filtersActiveCount(filters);
          return isEnabled && filterCount > 0
            ? <img src={ShowFiltersIcon} style={{ width: '6px', height: '6px' }} alt="Filter Active" /> 
            : 0;
        };

        return (
          <div className={classes.datatype} key={key}>
            <div className={classes.datatypeButton} key={key}>
              <IconButton
                onClick={enableDatatype(key)}
                onMouseEnter={datatypeIconMouseEnter}
                onMouseLeave={datatypeIconMouseLeave}
                style={{
                  color: 'white',
                  backgroundColor,
                  height: '40px',
                  padding: 0,
                  width: '40px',
                }}>
                <Badge 
                  badgeContent={filtersActive()}
                  color="error"                 
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  classes={{ badge: classes.customBadge }}>
                  <Icon color="inherit" />
                </Badge>
              </IconButton>
            </div>
            <div className={classes.disabledDatatypeTooltip}>
              {!isEnabled && datatypeIconIsHovered && plural}
            </div>
            <div className={isEnabled && datatypeIconIsHovered ? classes.enabledDatatypeTooltip : ''}>
              {isEnabled && datatypeIconIsHovered && plural}
            </div>
            <div className={isEnabled && filterIconIsHovered ? classes.filterIconTooltip : ''}>
              {isEnabled && filterIconIsHovered && 'Filters'}
            </div>
            <div className={isEnabled && turnOffIconIsHovered ? classes.turnOffIconTooltip : ''}>
              {isEnabled && turnOffIconIsHovered && 'Turn Off'}
            </div>
            <aside>
              {isEnabled && (
                <Fab
                  onClick={showFilters(key)}
                  onMouseEnter={filterIconMouseEnter}
                  onMouseLeave={filterIconMouseLeave}
                  className={classes.datatypeButton}
                  style={{ backgroundColor, color: 'white' }}>
                  <Badge 
                    badgeContent={filtersActiveCount()}
                    color="error"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    classes={{ badge: classes.customBadge }}>
                    <img src={ShowFiltersIcon} alt="Filters" />
                  </Badge>
                </Fab>
              )}
              {isEnabled && (
                <Fab
                  onClick={disableDatatype(key)}
                  onMouseEnter={turnOffIconMouseEnter}
                  onMouseLeave={turnOffIconMouseLeave}
                  className={classes.datatypeButton}
                  style={{ backgroundColor, color: 'white' }}>
                  <DisableIcon />
                </Fab>
              )}
            </aside>
          </div>
        );
      })}
      <div className={classes.resetMenu}>
        <div className={classes.resetDiv}>
          <div style={{ borderRadius: 5 }} onClick={enableResetMode()}>
            <ReplayIcon />
            RESET
          </div>
        </div >
        <aside>
          {resetOpen && (
            <React.Fragment>
              <div className={classes.resetDiv}>
                <div 
                  className={classes.resetMenuBox} 
                  onMouseLeave={disableResetMode()} 
                  onMouseEnter={enableResetFilterModal()} 
                  onClick={disableAllFilters()}>
                  <p>CLEAR FILTERS</p>
                </div>
                {resetFilterMenu && (
                  <div className={classes.resetTextBox}>
                    <div className={classes.caret} style={{ marginLeft: 8 }} />
                    <p>CLEAR ALL FILTERS</p>
                  </div>
                )}
              </div>
              <div className={classes.resetDiv}>
                <div
                  className={classes.resetMenuBox}
                  onMouseEnter={enableResetAllModal()}
                  onMouseLeave={disableResetMode()}
                  onClick={disableAllDatatypes()}>
                  <p>RESTORE DEFAULT</p>
                </div>
                {resetAllMenu && (
                  <div className={classes.resetTextBox}>
                    <div className={classes.caret} />
                    <p>CLEAR FILTERS AND CLOSE ALL LAYERS</p>
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </aside>
      </div>
    </div >
  );
};

MapMenu.propTypes = {
  classes: PropTypes.object,
  disableAllDatatypes: PropTypes.func.isRequired,
  disableAllFilters: PropTypes.func.isRequired,
  disableDatatype: PropTypes.func.isRequired,
  disableMapParcels: PropTypes.func.isRequired,
  disableResetMode: PropTypes.func.isRequired,
  enableDatatype: PropTypes.func.isRequired,
  enableMapParcels: PropTypes.func.isRequired,
  enableResetAllModal: PropTypes.func.isRequired,
  enableResetFilterModal: PropTypes.func.isRequired,
  enableResetMode: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  parcelsEnabled: PropTypes.bool.isRequired,
  router: PropTypes.object.isRequired,
  searches: PropTypes.object.isRequired,
  showFilters: PropTypes.func.isRequired,
  showReplayMenu: PropTypes.bool,
  showResetAllMenu: PropTypes.bool,
  showResetFilterMenu: PropTypes.bool,
  visibleDatatypes: PropTypes.array.isRequired,
};

export default withStyles(styles)(MapMenu);
