import grey from '@material-ui/core/colors/grey';

export default (theme) => ({
  ['@global']: {
    ['.rc-slider-tooltip']: {
      zIndex: 9,
    },
    ['.rc-slider-tooltip-inner']: {
      borderRadius: 0,
    },
    ['.sk-input-filter']: {
      borderRadius: 0,
    },
    ['.sk-panel__header.is-collapsable']: {
      marginBottom: 0,
    },
    ['.sk-panel__header']: {
      color: '#444',
      textTransform: 'uppercase',
    },
    ['.sk-item-list-option']: {
      padding: '0 5px',
    },
    ['.sk-item-list-option:hover']: {
      backgroundColor: '#eee',
    },
    ['.sk-item-list-option__count']: {
      color: '#999',
    },
    ['.sk-item-list-option.is-active .sk-item-list-option__text']: {
      color: '#444',
    },
    ['.sk-range-slider']: {
      margin: `0 ${theme.spacing()}px ${theme.spacing(2)}px`,
      padding: 0,
    },
    ['.sk-range-input']: {
      display: 'flex',
      justifyContent: 'center',
    },
    ['.sk-range-input__input']: {
      borderRadius: '0 !important',
      width: 70
    },
    ['.sk-reset-filters']: {
      borderRadius: 0,
      cursor: 'pointer',
      fontSize: '90%',
      fontWeight: 'bold',
      marginTop: theme.spacing(),
      padding: 0,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    ['.sk-reset-filters.is-disabled']: {
      display: 'none',
    },
    ['.sk-reset-filters__reset']: {
      margin: '0 auto',
    },
    ['.sk-toggle-option']: {
      backgroundColor: 'transparent',
      borderRight: 'none',
      color: '#555',
      fontSize: '1em',
      fontWeight: 'normal',
      maxHeight: 'auto',
      padding: 0,
      textDecoration: 'none',
      whiteSpace: 'nowrap',
    },
    ['.sk-toggle-option:first-child']: {
      borderRadius: 0,
    },
    ['.sk-toggle-option:last-child']: {
      borderRadius: 0,
    },
  },

  appbarSpacer: theme.mixins.toolbar,
  drawerPaper: {
    maxWidth: '100vw',
    overflowY: 'hidden',
    paddingBottom: 32,
    width: 350,
  },
  filterHeader: {
    color: grey[500],
    flex: 1
  },

  header: {
    backgroundColor: 'white !important',
    borderBottom: `1px solid ${grey[300]}`,
    ['& h1']: {
      color: `${grey[500]} !important`
    }
  },

  idArrayChip: {
    marginBottom: 2
  },
  idArrayChips: {
    listStyle: 'none',
    paddingLeft: 0
  },
  idArrayInputElement: {
    color: 'black !important',
    fontFamily: 'Arial, sans-serif',
    outlineColor: 'none !important'
  },
  idArrayInputField: {
    width: '100%'
  },
  idArrayInputFocused: {
    border: 'none'
  },
  idArrayInputIcon: {
    marginTop: [2, '!important']
  },
  idArrayInputWrapper: {
    height: 32,
    paddingLeft: 0,
    ['& > fieldset']: {
      borderColor: 'rgb(0, 0, 0, 0.23) !important',
      borderWidth: [1, '!important']
    }
  },
  zipCodeChipActive: {
    backgroundColor: 'pink',
  },
  filtersContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  filters: {
    height: '90%',
    overflowX: 'hidden',
    overflowY: 'scroll',
  },

  filter: {
    position: 'relative',
    ['& > div']: {
      padding: `${theme.spacing()}px ${theme.spacing(3)}px`,
      position: 'relative',

      ['&$sortIcon']: {
        position: 'absolute',
        top: theme.spacing(-0.5),
        right: 0,
        paddingLeft: 0,
        paddingBottom: 0,
        zIndex: 1,
        cursor: 'pointer',
        background: 'transparent',

        ['& > svg']: { color: '#999 !important' },
        ['&:hover > svg']: { color: 'black !important' },

        ['&$sorted']: {
          ['& > svg, &:hover > svg']: {
            color: 'black !important',
          }
        }
      },
    },
    ['& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button']: {
      ['-webkit-appearance']: 'none',
      ['-moz-appearance']: 'none',
      appearance: 'none',
      margin: 0,
    }
  },
  accordionContainer: {
    marginTop: '30px',
    padding: 0,
    width: '95%',
  },
  accordionFilter:{
    padding: 0,
    textAlign: 'left',
    boxShadow: 'none',
  },
  accordionDetails:{
    padding: 0,
  },
  sortContainer: {
    marginTop: '-5px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  sortFilter: {
    ['& > div']: {
      textAlign: 'left',
      padding: 0,
      maxWidth: '100vw',
    },
  },
  sorted: {},
  sortIcon: {},
});
