import React from 'react';
import PropTypes from 'prop-types';
import {
  CheckboxItemList,
  RangeFilter,
  RangeSliderInput,
  Toggle,
  MenuFilter,
  Panel,
  RefinementListFilter,
} from 'searchkit-react16';
import BooleanFilter from './BooleanFilter';
import PortfolioFilter from './PortfolioFilter';
import CurrencyRangeInput from './CurrencyRangeInput';
import CustomDynamicRange from './CustomDynamicRange';
import DateRangeInput from './DateRangeInput';
import IdArrayFilter from './IdArrayFilter';
import CityArrayFilter from './CityArrayFilter';
import ZipCodeArrayFilter from './ZipCodeArrayFilter';
import RelatedAndCustomIdsArrayFilter from './RelatedAndCustomIdsArrayFilter';
import InputFilter from './InputFilter';
import PercentageRange from './PercentageRange';
import PropertySizeRange from './PropertySizeRange';
import RangeInput from './RangeInput';
import DateRange from './DateRange';

const Filter = ({ isCollapsed, type, ...props }) => {
  switch (type) {
    case 'text':
    case 'textArray':
      return <InputFilter {...props} />;

    case 'dynamicRange':
      return <CustomDynamicRange {...props} rangeComponent={RangeSliderInput} />;

    case 'simpleRange':
      const srProps = {
        interval: 1,
        min: -Number.MAX_SAFE_INTEGER,
        max: Number.MAX_SAFE_INTEGER,
        showHistogram: false
      };
      const rangeComponent = props.subtype === 'currency' ? CurrencyRangeInput : RangeInput;
      return <RangeFilter {...srProps} {...props} rangeComponent={rangeComponent} />;

    case 'propertySizeRange':
      const psrProps = {
        interval: 1,
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
        showHistogram: false
      };
      return <PropertySizeRange {...psrProps} {...props} rangeComponent={RangeInput} />;

    case 'dateRange':
      return <DateRange {...props} rangeComponent={DateRangeInput} />;

    case 'staticRange':
      return <RangeFilter {...props} rangeComponent={RangeSliderInput} />;

    case 'percentageRange':
      const prProps = {
        min: 0,
        max: 100,
        interval: 1,
        showHistogram: false
      };
      return <PercentageRange {...prProps} {...props} rangeComponent={RangeInput} />;
    
    case 'portfolioFilter':
      return (
        <PortfolioFilter
          {...props}
          field={props.field}
          title={props.title}
          translations={{ 'isNotPortfolio': 'Exclude Portfolio', 'isPortfolio': 'Portfolio Only' }}
          id={props.id}
          listComponent={CheckboxItemList} />
      );

    case 'refinement':
    case 'refinementNews':  
      const refProps = {
        orderKey: props.isNews ? '_count' : '_key',
        orderDirection: props.isNews ? 'desc' : 'asc'
      };
      return (
        <RefinementListFilter
          {...props}
          {...refProps}
          containerComponent={(
            <Panel
              collapsable={true}
              defaultCollapsed={isCollapsed} />
          )} />
      );

    case 'boolean':
      return (
        <BooleanFilter
          field={props.field}
          title={props.title}
          id={props.id}
          listComponent={CheckboxItemList} />
      );

    case 'toggle':
      const toggleProps = {};
      // if (props.field === 'clientId') {
      //   toggleProps.include = [props.clientId];
      //   toggleProps.translations = { 'All': 'All', [props.clientId]: 'My Company' };
      // }

      return (
        <MenuFilter
          {...props}
          {...toggleProps}
          listComponent={Toggle} />
      );

    case 'idArray':
      return (
        <IdArrayFilter {...props} />
      );

    case 'cityArray':
      return (
        <CityArrayFilter {...props} />
      );
      
    case 'zipCodeArray':
      return (
        <ZipCodeArrayFilter {...props} />
      );

    case 'relatedAndCustomIdsArray':
      return (
        <RelatedAndCustomIdsArrayFilter {...props} />
      );
  }
};

Filter.propTypes = {
  field: PropTypes.string.isRequired,
  id: PropTypes.string,
  isCollapsed: PropTypes.bool,
  isNews: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  subtype: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
};

Filter.defaultProps = {
  isCollapsed: true,
  isNews: false
};

export default Filter;
